import React ,{useState}from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import { Button, Flex ,message,Modal, Form} from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, EyeOutlined  } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FormFields from "../../../utils/FormFields";
import ScenarioServices from "../../../services/services/ScenarioServices";
import HomePageServices from '../../../services/services/HomePageServices';

const data = [
    {
        shortname: "App",
        displayname: "3D Experience",
        discription: "",
        username: "Ronald Richards",
        datetime: "30/08/2024 - 11:00AM",
    },
    {
        shortname: "App",
        displayname: "3DXCAE",
        discription: "",
        username: "Cody Fisher",
        datetime: "02/09/2024 - 10:00AM",
    },
    {
        shortname: "App",
        displayname: "KeyCloak",
        discription: "Changed checker & OPEX and 2 More Properties",
        username: "Marvin McKinney",
        datetime: "05/09/2024 - 14:00PM",
    },
];
const HomeNotifications = ({ notificationData, getNotificationData, ...props }) => {
    
    notificationData = notificationData?.filter((f) => f.notificationtype !== "Request"); 
    const notificationDataLess = notificationData?.slice(0, 5); 
    return (
        <React.Fragment>
            { notificationDataLess?.length > 0 && ( 
                <>
            <PageTitleHeading text={"Notifications"} />
            <div className='notifications mb-2'>
                <ul>
                    {
                        notificationDataLess?.length > 0 &&
                        notificationDataLess?.map(item => {
                            return <React.Fragment key={Math.random()}>
                                <li>
                                    <NotificationListItem item={item} getNotificationData={getNotificationData} />
                                </li>
                            </React.Fragment>
                        } ) ||"" 
                    }
                </ul>
                {notificationData.length && <Link to='/notifications' className='btn btn-sm btn-primary'>View All ({notificationData.length})</Link>}
            </div></>)||""}
        </React.Fragment>
    )
}

export default HomeNotifications


export const NotificationListItem = ({ item, getNotificationData }) => {
    const [rejectedModel, setRejectedModel] = useState(false);
    const [approvedModel, setApprovedModel] = useState(false);
    const [addApproverBtn, setaddApproverBtn] = useState(false);
    const [sendAction, setSendAction] = useState(false);
    const [changeTypeForm] = Form.useForm();
    
    const handleView=async (dataObj)=>{
        try 
        {
            const paramsObj  ={id:dataObj.id}; 
            const response = await HomePageServices.UpdateNotificationAsRead(
                paramsObj
              );
        }catch(err)
        {

        }
    }
    const handleApproval =()=>{ 
        setApprovedModel(true); 
    }

    
    const handleRejection =()=>{
        setRejectedModel(true); 
    }
    
    const updateScenarioV2Stage = async (data) => {
        try {
          const url = window.location.href;
          const userData = localStorage.getItem("userData");
          const userDataJson = JSON.parse(userData);
    
          const paramsObj = {
            id: data?.scenarioId,
            Status: data?.status1,
            Reason: data?.RejectedReason,
            approverEmail: data?.approvers_emails,
            collaboratorEmail: data?.collaborators_emails,
            pageUrl: url,
            ApproverName: data?.name,
            scenarioName: data?.scenarioName,
          };
    
          const response = await ScenarioServices.updateScenarioV2LifecycleStage(
            paramsObj
          );
    
          if (response.data.code === 200) {
            getNotificationData();
            setSendAction(false);
            message.success("Updated successfully");
            setRejectedModel(false);
            setApprovedModel(false);
          } else {
            message.error(response.data.msg);
          }
        } catch (err) {}
      };

    return <>
        <div className='card card-body py-2 mb-2'>
            <Flex gap={2} justify='space-between' align='center'>
                <Flex gap={2} vertical justify='flex-start'>
                    <Flex gap={8} align='center' justify='flex-start'>
                        {/* <div className='shortname' style={{"--bg-color":"#FFB381"}}>{item?.modulename}</div>
                         */}
                        <div className='shortname' style={{ "--bg-color": item?.color }}>{item?.modulename}</div>

                        <div className={item?.action === 'Unread' && 'fw-bold'}>{item?.displayname}</div>

                    </Flex>
                    <div className='fw-bold'>{item?.discription}</div>
                    <Flex gap={8} align='center' justify='flex-start'>
                        <div>User: <span  >{item?.name}</span></div>
                        <div>Date/Time: <span   >{item?.formatted_date} {item?.formatted_time}</span></div>
                    </Flex>
                </Flex>
                <Flex gap={8}>
                    {(item?.notificationtype === "Approvals" || item?.notificationtype === "Request") && (
                        // <Link to={`/scenario-list-v2/${item?.dataid}`} className="link">View</Link>)
                        // || (<Link to={`/portfolio/${item?.modulename}/${item?.dataid}`} state={{ activeTabKey: 'tab_changelogAlert' }} className='link'>View Changes</Link>
                        
                        <Link to={`/scenario-list-v2/${item?.dataid}`} className="link"><Button type="text"  onClick={()=>handleView(item)}  icon={<EyeOutlined style={{ color: "gray", }} />} />  </Link>)
                        || (<Link to={`/portfolio/${item?.modulename}/${item?.dataid}`} state={{ activeTabKey: 'tab_changelogAlert' }} className='link'><Button type="text" onClick={()=>handleView(item)}  icon={<EyeOutlined style={{ color: "gray", }} />} /> </Link>

                        )}


                    {/* { (item?.scenariostatus==="Approval Pending"   &&  item?.notificationtype === "Approvals") && (
                        <>
                           
                            <Button type="text"   icon={<CheckOutlined style={{ color: "green", }} onClick={handleApproval} />} />
                            <Button type="text"   icon={<CloseOutlined style={{ color: "red", }} onClick={handleRejection} />} />

                        </>
                    )} */}
                </Flex>
            </Flex>
        </div>

        <Modal
        destroyOnClose
        header={false}
        footer={false}
        requiredMark={true}
        maskClosable={false}
        open={rejectedModel}
        onCancel={() => {
          setRejectedModel(false);
        }}
      >
        <Form
          form={changeTypeForm}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          // initialValues={initialValue}
          className="mt-4"
          onFinish={async (formData) => {
            //Update Rejected
            updateScenarioV2Stage("Rejected", formData?.comment);
          }}
        >
          <FormFields
            type={`textarea`}
            label={`Reject Reason`}
            name={`comment`}
            formClassName={`mb-2`}
            rows={3}
          />

          <Button
            type={`primary`}
            htmlType={`submit`}
            className="w-100"
            //loading={changeTypeLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        header={false}
        footer={false}
        requiredMark={true}
        maskClosable={false}
        open={approvedModel}
        onCancel={() => {
          setApprovedModel(false);
        }}
      >
        <Form
          form={changeTypeForm}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          // initialValues={initialValue}
          className="mt-4"
          onFinish={async (formData) => {
            //Update Rejected
            setSendAction(true);
            updateScenarioV2Stage("Approved", formData?.comment);
          }}
        >
          <FormFields
            type={`textarea`}
            label={`Remarks`}
            name={`comment`}
            formClassName={`mb-2`}
            rows={3}
          />

          <Button
            type={`primary`}
            htmlType={`submit`}
            className="w-100"
            loading={sendAction}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
}