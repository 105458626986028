import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL


async function getNotificationData(obj) {
    const url = `${API_BASE_URL}homepage/getnotificationdata`;
    let response = await http.post(url, obj);
    return response;
}

async function UpdateNotificationAsRead(obj) {
    const url = `${API_BASE_URL}homepage/updatenotificationasread`;
    let response = await http.post(url, obj);
    return response;
}

async function UpdateUsernotificationToken(obj) {
    const url = `${API_BASE_URL}user/updateusernotificationtoken`;
    let response = await http.post(url, obj);
    return response;
}


 

const HomePageServices = {
    getNotificationData,
    UpdateNotificationAsRead,
    UpdateUsernotificationToken
}

export default HomePageServices;
