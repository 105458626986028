import "./Initiative.scss";
import { Tabs } from "antd";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router";
import { useEffect } from "react";
import TabDiagrams from "../../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams";
import TabChangeLogAlerts from "../../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import TabLinks from "../../portfoliosingleentry/portfolioTabs/tablinks/TabLinks";
import TabConnections from "../../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections";
import TabComments from "../../portfoliosingleentry/portfolioTabs/tabcomments/TabComments";
import TabBasicInformation from "../plant/tabs/TabBasicInformation";
import {
  PageTitleHeading,
  getRecentlyView_ScenarioName,
} from "../../../utils/Common";
import { GoBack } from "../../../utils/GoBackHistory";

const PlantEntry = (props) => {
  const { id = null, moduleName, scenarioId } = useParams();
  const location = useLocation();
  if (!location?.state) {
    location.state =
      JSON.parse(sessionStorage.getItem("edit-portfolio-" + moduleName)) ||
      null;
  } else {
    sessionStorage.setItem(
      "edit-portfolio-" + moduleName,
      JSON.stringify(location?.state)
    );
  }
  const [activeTabKey, setActiveTabKey] = useState("tab_information");
  const [initiativeData, setPlantData] = useState({});
  // const { tabDisabled, toggleTabDisabled } = useTabContext();
  const [portfolioId, setPortfolioId] = useState(null);
  const [scenarioName, setScenarioName] = useState();
  const [refreshTab, setrefreshTab] = useState(false);

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (location?.state?.activeTabKey) {
      handleTabChange(location?.state?.activeTabKey);
    }

    if (id?.toLowerCase() === "add") {
      setPortfolioId(null);
    } else {
      setPortfolioId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getScenarioName();
  }, [scenarioId]);

  const getScenarioName = async () => {
    const scenario = await getRecentlyView_ScenarioName(scenarioId);
    if (scenario?.length) {
      setScenarioName(scenario[0].scenarioName);
    }
  };

  const tabsProps = {
    itemData: initiativeData,
    setActiveTabKey,
    handleTabChange,
    refreshTab,
    setrefreshTab,
    // toggleTabDisabled,
    // tabDisabled,
    id: id?.toLowerCase() === "add" ? null : id,
    moduleName: moduleName,
  };

  const PlantTabs = [
    {
      label: `Information`,
      key: "tab_information",
      children: (
        <TabBasicInformation
          {...tabsProps}
          setPortfolioData={setPlantData}
          basePath={location?.pathname}
          locationData={location?.state}
        />
      ),
    },
    {
      label: `Relations`,
      key: "tab_connections",
      disabled: portfolioId ? false : true,
      children: <TabConnections {...tabsProps} />,
    },
    {
      label: `External Links`,
      key: "tab_links",
      disabled: portfolioId ? false : true,
      children: <TabLinks {...tabsProps} />,
    },
    {
      label: `Diagram`,
      key: "tab_diagram",
      disabled: portfolioId ? false : true,
      children: (
        <TabDiagrams {...tabsProps} titleName={initiativeData?.DisplayName} />
      ),
    },
    {
      label: `Comments`,
      key: "tab_comments",
      disabled: portfolioId ? false : true,
      children: <TabComments {...tabsProps} />,
    },
    {
      label: `Change log/ Alerts`,
      key: "tab_changelogAlert",
      disabled: portfolioId ? false : true,
      children: <TabChangeLogAlerts {...tabsProps} />,
    },
  ];

  return (
    <div className=" mb-5 text-left">
      <div className="d-flex justify-content-between">
        <div>
          <PageTitleHeading
            text={(() => {
              if (initiativeData?.displayname) {
                return (
                  <span>
                    Initiative: {initiativeData?.LevelID}{" "}
                    {initiativeData?.displayname}
                  </span>
                );
              }
              return <span>Add New Initiative</span>;
            })()}
          />
        </div>

        {scenarioId && (
          <div
            style={{
              background: "#ffaa02",
              paddingTop: 1,
              paddingLeft: 5,
              paddingRight: 5,
              margin: 3,
              borderRadius: 5,
              height: 25,
            }}
          >
            {scenarioName ? "CURRENT WORKSPACE: " + scenarioName : ""}
          </div>
        )}

        <div className="my-3">
          <GoBack
            className={`ml-2 pull-right`}
            path={location?.state?.from}
            state={location?.state}
            back
          />
        </div>
      </div>
      <Tabs
        type="card"
        className="business-capability-entry"
        activeKey={activeTabKey}
        onChange={handleTabChange}
        items={PlantTabs}
      />
    </div>
  );
};

export default PlantEntry;
