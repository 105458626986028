import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, message, Row, Spin, Upload } from "antd";
import GlobalSearchServices from "../../services/services/GlobalSearchServices";
import Learnig from './../../assets/learning.jpeg';
import { settings } from "../../utils/settingCommon";
import Delayed from "../../utils/Delayed";

const ShortVideoGuide = () => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [shortVideo, setShortVideo] = useState(null);

    useEffect(()=>{
        getShortVideoUpload();
    },[])

    const handleUpload = async () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('image', file);
        });
        setUploading(true);
    
        try {
            let res = []
            if(fileList.length>0){
                const response = await fetch(`${process.env.REACT_APP_API_URL}etl/uploadMedia`, {
                    method: 'POST',
                    body: formData,
                });
                res = await response.json();
            }

            if(res?.blobUrls?.length>0){
                let obj = {
                    "linkUrl": res?.blobUrls[0],
                    "linkId": settings?.home_page || "",
                };
                setShortVideo(null);
                let response = await GlobalSearchServices?.setSettings(obj);
                if(response?.data?.linkUrl){
                    setShortVideo(response?.data?.linkUrl);
                }
                message.success('upload successfully.');
            }else{
                message.error('upload failed.');
            }
        } catch (error) {
            message.error('upload failed.');
        } finally {
            setUploading(false);
        }
    };

    const uploadProps = {
        multiple:false,
        accept:"video/*",
        maxCount:1,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            // setFileList([...fileList, file]);
            setFileList([file]);
            return false;
        },
        fileList,
    };

    const getShortVideoUpload = async () => {
        
        try {
            setUploading(true);
            let response = await GlobalSearchServices?.getSettings(settings?.home_page || "");
            if(response?.data?.linkUrl){
                setShortVideo(response?.data?.linkUrl);
            }
        } catch (error) {
            console.error("error:",error)
        } finally {
            setUploading(false);
        }
    }

    return (
        <Spin spinning={uploading}>
            <Row>
                <Col span={12}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        style={{
                            marginTop: 16,
                        }}
                    >
                        {uploading ? "Uploading" : "Start Upload"}
                    </Button>
                </Col>
                <Col span={12}>
                    {
                        (()=>{
                            if(shortVideo){
                                return <React.Fragment>
                                    <div class="embed-responsive embed-responsive-21by9">
                                        <video width="320" height="240" controls>
                                            <source src={shortVideo} type="video/mp4" className="embed-responsive-item mt-0 pt-0"/>
                                            <source src={shortVideo} type="video/ogg" className="embed-responsive-item mt-0 pt-0"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </React.Fragment>
                            }else{
                                return <React.Fragment>
                                    <Delayed waitBeforeShow={1000}>
                                        <img src={Learnig} alt='Learning'/>
                                    </Delayed>
                                </React.Fragment>
                            }
                        })()
                    }
                    
                </Col>
            </Row>
            
        </Spin>
    );
};
export default ShortVideoGuide;
