import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router';
import BusinessProcessGrid from '../../businessProcess/BusinessProcessGrid';
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import BusinessProcessList from '../../businessProcess/BusinessProcessList';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { showViewType } from '../common/PortfolioCommon';

const ApqcProcesses = (props) => {
    const location = useLocation()
    const [activePage,setActivePage] = useState(1);
    const {moduleName} = useParams();
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});
    const [uniquelevelid,setuniquelevelid] = useState([]);      
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);

    useState(()=>{
        setActivePage(location?.state?.defaultActiveKey || 1)
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    },[])

    const HeaderContnet = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={moduleData?.name || moduleName}
        addBtnTooltip={`Level 1`}
        filterOptions={filterOptions}
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
        lists={uniquelevelid}
    />

    return (
        <div className='business-process-master'>
            { 
                activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <BusinessProcessGrid 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <BusinessProcessList 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                    setuniquelevelid={setuniquelevelid}
                    
                />
            }
        </div>
    )
}

export default ApqcProcesses