import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  Flex,
  Form,
  Layout,
  message,
  Result,
  Select,
} from "antd";
import LoadingBar from "react-top-loading-bar";
import { PageTitleHeading } from "../../../utils/Common";
import CompareChart from "./CompareChart";
import ScenarioServices from "../../../services/services/ScenarioServices";
import { useLocation } from "react-router";
import { json, useNavigate, useParams } from "react-router-dom";

function CompareScenarioV2() {
  const location = useLocation();
  const loadingBarRef = useRef(null);
  const [filterForm] = Form.useForm();
  const selectDefaultProps = {
    showSearch: true,
    style: { minWidth: "150px" },
    size: "medium",
    allowClear: true,
  };
  const [portfolioList, setPortfolioList] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [finalChartData, setFinalChartData] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  //   const data = [
  //     {
  //       scenario_id: 332,
  //       name: "Sprint Demo 15",
  //       startTime: "2024-10-01T23:58:20.657Z",
  //       endTime: "2024-12-06T23:57:22.786Z",
  //       portfolio: [
  //         {
  //           portfolio_id: 4,
  //           portfolioname: "Application",
  //           startTime: "2024-10-17T13:00:00.000Z",
  //           endTime: "2024-12-06T23:57:22.786Z",
  //           color: "#FFB381",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 18,
  //           portfolioname: "APQC Process",
  //           startTime: "2024-10-09T23:57:43.656Z",
  //           endTime: "2024-10-09T23:57:43.656Z",
  //           color: "#D4E2C6",
  //           count_items: 1,
  //         },

  //         {
  //           portfolio_id: 1,
  //           portfolioname: "Business Capability",
  //           startTime: "2024-10-01T23:58:20.657Z",
  //           endTime: "2024-10-09T23:58:12.772Z",
  //           color: "#B2E5E5",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 5,
  //           portfolioname: "Business Process",
  //           startTime: "2024-10-12T01:16:05.176Z",
  //           endTime: "2024-10-12T01:16:05.176Z",
  //           color: "#FFF280",
  //           count_items: 1,
  //         },
  //       ],
  //     },

  //     {
  //       scenario_id: 346,
  //       name: "Test  1809 Awa",
  //       startTime: "2024-09-01T01:36:12.430Z",
  //       endTime: "2025-02-07T13:00:00.000Z",
  //       portfolio: [
  //         {
  //           portfolio_id: 4,
  //           portfolioname: "Application",
  //           startTime: "2024-09-01T01:36:12.430Z",
  //           endTime: "2025-02-07T13:00:00.000Z",
  //           color: "#FFB381",
  //           count_items: 4,
  //         },

  //         {
  //           portfolio_id: 18,
  //           portfolioname: "APQC Process",
  //           startTime: "2024-10-08T01:36:50.782Z",
  //           endTime: "2024-11-09T01:36:40.216Z",
  //           color: "#D4E2C6",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 5,
  //           portfolioname: "Business Process",
  //           startTime: "2024-10-02T01:37:45.944Z",
  //           endTime: "2024-12-07T01:37:30.621Z",
  //           color: "#FFF280",
  //           count_items: 2,
  //         },

  //         {
  //           portfolio_id: 11,
  //           portfolioname: "Initiative",
  //           startTime: "2024-09-25T01:38:00.902Z",
  //           endTime: "2024-11-09T01:38:06.535Z",
  //           color: "#D4E2D4",
  //           count_items: 6,
  //         },

  //         {
  //           portfolio_id: 12,
  //           portfolioname: "Organization",
  //           startTime: "2024-10-10T01:40:02.037Z",
  //           endTime: "2024-11-11T13:00:00.000Z",
  //           color: "#C4DFDF",
  //           count_items: 3,
  //         },
  //       ],
  //     },
  //   ];

  useEffect(() => {
    getscenariocomparison();

    // getUniquePortFolioFromData();
    // filterDataPortfoliowise();
  }, []);

  useEffect(() => {
    filterDataPortfoliowise();
  }, [selectedPortfolio]);

  useEffect(() => {
    getUniquePortFolioFromData();
    filterDataPortfoliowise();
  }, [data]);

  const getscenariocomparison = () => {
    const integer_array = location?.state?.compare?.compareIds?.map(Number);

    const obj = {
      scenario_ids: integer_array,
    };

    // const obj = {
    //   scenario_ids: [346, 332],
    // };

    ScenarioServices.getscenariocomparison(obj)
      .then((result) => {
        if (result.status === 200) {
          if (result.data) {
            setData(result.data);
          }
        } else {
          message.error("error occured during getting daya");
        }
      })
      .catch((err) => {});
  };

  const getUniquePortFolioFromData = () => {
    const uniquePortfolios = Array.from(
      new Set(
        data?.flatMap((scenario) =>
          scenario?.portfolio?.map((portfolio) =>
            JSON.stringify({
              portfolioname: portfolio?.portfolioname,
              id: portfolio?.portfolio_id,
              color: portfolio?.color,
            })
          )
        )
      )
    )?.map((item) => JSON.parse(item));

    setPortfolioList(uniquePortfolios);
  };

  const filterDataPortfoliowise = () => {
    const dataFinal = [];
    const scenarioData1 = data?.map((scenario) => {
      const scenarioData = data?.find((event) => event.name === scenario.name);

      dataFinal.push({
        type: scenarioData.type,
        id: scenarioData.id,
        name: scenarioData.name,
        start: new Date(scenarioData.start),
        end: new Date(scenarioData.end),
        progress: scenarioData.progress,
        hideChildren: scenarioData.hideChildren,
        color: scenarioData.color,
        count_items: scenarioData.count_items,
      });

      scenarioData?.portfolio?.map((item) => {
        if (selectedPortfolio.includes(item.portfolio_id)) {
          const {
            type,
            portfolio_id,
            portfolioname: name,
            start,
            end,
            progress,
            project,
            color,
            count_items,
            data: dataItem,
          } = item;

          const id = portfolio_id + "_" + scenarioData.id;
          dataFinal.push({
            type,
            id,
            name,
            start: new Date(start),
            end: new Date(end),
            color,
            count_items,
            progress,
            project,
          });

          if (dataItem.length) {
            dataItem.map((item1) => {
              const {
                type,
                item_id,
                displayname,
                start,
                end,
                progress,
                project,
              } = item1;

              const id1 = item_id + "_" + portfolio_id + "_" + scenarioData.id;

              //if (!dataFinal.some((item) => item.id === id1)) {
                
                dataFinal.push({
                  type,
                  id: id1,
                  name: displayname,
                  start: new Date(start),
                  end: new Date(end),
                  progress: id,
                  project 
                });
              //}
            });
          }
        }
      });

      // dataFinal.push({
      //   type: scenarioData.type,

      //   name: scenarioData.name,
      //   scenario_id: scenarioData.scenario_id,
      //   startTime: scenarioData.startTime,
      //   endTime: scenarioData.endTime,
      //   color: "",
      //   count_items: "0",
      // });

      // scenarioData?.portfolio?.map((item) => {
      //   if (selectedPortfolio.includes(item.portfolio_id)) {
      //     const { portfolioname, startTime, endTime, color, count_items } =
      //       item;
      //     const name = scenarioData.name + "-" + portfolioname;
      //     dataFinal.push({ name, startTime, endTime, color, count_items });
      //   }
      // });
    });

    setFinalChartData(dataFinal);
  };

  const handleChange = (tags) => {
    setSelectedPortfolio(tags);
  };

  console.log("object finalChartData", finalChartData);
   

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef} />
      <Layout>
        <Layout.Content className="business-capability-landscape-report">
          <section>
            <PageTitleHeading text={"Compare Scenario"} />
          </section>
          <section>
            <Flex className="overflow-x pb-2">
              <Form
                form={filterForm}
                layout="inline"
                style={{ maxWidth: "none" }}
                className="mt-2"
              >
                <Form.Item label="Select Portfolio">
                  <Select
                    {...selectDefaultProps}
                    mode="tags"
                    placeholder={"Select Portfolio"}
                    allowClear={true}
                    onChange={handleChange}
                  >
                    {portfolioList?.map((item) => {
                      return (
                        <Select.Option value={item.id}>
                          <span
                            className="portfolio-option-color"
                            style={{ "--color": item?.color }}
                          ></span>
                          {item.portfolioname}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                
              </Form>
            </Flex>
            <Flex className="overflow-x pb-2">
            <Button type='primary' onClick={()=>{setTimeout(() => {
              navigate(`/compare-scenario-v2`);
            }, 1000); }}  className='px-4'>Back</Button>
            </Flex>
          </section>
          <section>
            <Card className="mt-2">
              <Flex gap={8} className="overflow-x pb-2">
               {  finalChartData && finalChartData.length && (<CompareChart events={finalChartData} />) || "Data not found" }
              </Flex>
            </Card>
          </section>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
}

export default CompareScenarioV2;
