import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList(searchTerm = '') {
  const url = `${API_BASE_URL}globalsearch?q=${searchTerm}`;
  let response = await http.get(url);
  return response;
}

async function getSettings(linkId) {
  
  const url = `${API_BASE_URL}settings?linkId=`+linkId;
  let response = await http.get(url);
  return response;
}

async function setSettings(formData) {
  const url = `${API_BASE_URL}settings`;
  let response = await http.post(url,formData);
  return response;
}

const GlobalSearchServices = {
  getList,
  setSettings,
  getSettings,
};

export default GlobalSearchServices;
