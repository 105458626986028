import React from 'react';
import { Bar } from '@ant-design/plots';

const data = [
    { year: 'Applied', value: 38 ,color:'#fff280'},
    { year: 'Work In Progress', value: 52,color:'#c0b4fc' },
    { year: 'Total', value: 61,color:'#98dc9c' },
];

const ScenarioChart = () => {

    const config = {
        data,
        xField: 'year',
        yField: 'value',
        sort: {
            reverse: true,
        },
        label: {
            // text: 'frequency',
            // formatter: '.1%',
            style: {
                // textAlign: (d) => (+d.value > 0.008 ? 'right' : 'start'),
                // fill: (d) => (+d.value > 0.008 ? '#fff' : '#000'),
                dx: (d) => (+d.value > 0.008 ? 25 : 5),
            },
            text: 'value',
            // textBaseline: 'bottom',
            position: 'right',
            textAlign: 'center',
        },
        tooltip:false,
        style: {
            fill: ({ color }) => {
                return color;
            },
            maxWidth: 40,
        },
        state: {
            // unselected: { opacity: 0.5 },
            // selected: { lineWidth: 3, stroke: 'red' },
          },
          interaction: {
            elementSelect: true,
          },
          
        scale: {
            x: { 
                padding: 2,
            },
        },
    };
    return <Bar {...config} height={350} />;
}

export default ScenarioChart
