import { Card, Row, Col, Collapse } from 'antd';
import React,{ useState } from 'react';
 
import LifeCycleStatusChart from './LifeCycleStatusChart';
import ScenarioChart from './ScenarioChart';
import HomePortfolioCharts from './HomePortfolioCharts';
import { PageTitleHeading } from '../../../utils/Common'

const HomeCharts = ({ ...props }) => {
    const { Panel } = Collapse;
    const [expandIconPosition, setExpandIconPosition] = useState('end');
  
    return (
        <React.Fragment>
            <PageTitleHeading text={"Widgets"} />
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <div className='homelifecyclestatus'>
                        <Card size='small'  >
                            <Collapse defaultActiveKey={['1']} expandIconPosition={expandIconPosition} >
                                <Panel header="Lifecycle Status" key="1">
                                    {/* <Card title="Lifecycle Status" size='small'> */}
                                    <LifeCycleStatusChart />
                                    {/* </Card> */}
                                </Panel>
                            </Collapse>
                        </Card>
                    </div>
                </Col>
                <Col span={12}>

                    <div className='homescenarios'>
                        <Card size='small'>
                            <Collapse defaultActiveKey={['1']} expandIconPosition={expandIconPosition} >
                                <Panel header="Scenarios" key="1">
                                    {/* <Card title="Scenarios" size='small'> */}
                                    <ScenarioChart />
                                    {/* </Card> */}
                                </Panel>
                            </Collapse>
                        </Card>
                    </div>
                </Col>
                <Col span={24}>
                    <div className='homePortfolioSummary'>
                        <Card size='small'>
                            <Collapse defaultActiveKey={['1']} 
                            expandIconPosition={expandIconPosition} >
                                <Panel header="Portfolio Summary" key="1">

                                    <HomePortfolioCharts />

                                </Panel>
                            </Collapse>
                        </Card>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default HomeCharts