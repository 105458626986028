import './../../businessProcess/BusinessProcessEntry.scss';
import { Tabs } from "antd";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router";
import { useEffect } from 'react';
import TabBPBasicInformation from '../../businessProcess/BPETabs/TabBPBasicInformation/TabBPBasicInformation';
import TabConnections from '../../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections';
import TabLinks from '../../portfoliosingleentry/portfolioTabs/tablinks/TabLinks';
import TabDiagrams from '../../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams';
import TabComments from '../../portfoliosingleentry/portfolioTabs/tabcomments/TabComments';
import TabChangeLogAlerts from '../../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts';
import { getRecentlyView_ScenarioName, PageTitleHeading } from '../../../utils/Common';
import { GoBack } from '../../../utils/GoBackHistory';



const ApqcProcessesEntry = (props) => {
    let location = useLocation();
    
    if (!location?.state) {
        location.state = JSON.parse(sessionStorage.getItem('editData-BP')) || null;
    } else {
        sessionStorage.setItem('editData-BP', JSON.stringify(location?.state))
    }
 

    const [activeTabKey, setActiveTabKey] = useState("tab_information");
    const [businessProcessData, setBusinessProcessData] = useState({});
    const { id = null, moduleName = null, scenarioId } = useParams();
    const [portfolioId, setPortfolioId] = useState(null);
    const [scenarioName, setScenarioName] = useState();
    const [refreshTab, setrefreshTab] = useState(false);


    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    useEffect(() => {
        getScenarioName();
    }, [scenarioId]);

    const getScenarioName = async () => {
        const scenario = await getRecentlyView_ScenarioName(scenarioId);
        if (scenario?.length) {
            setScenarioName(scenario[0]?.scenarioName);
        }
    };

    useEffect(() => {
        if (location?.state?.activeTabKey) {
            handleTabChange(location?.state?.activeTabKey);
        }
        if (id?.toLowerCase() === "add") {
            setPortfolioId(null);
        } else {
            setPortfolioId(id);
        }

        console.log("awadhesh.............",location?.state?.activeTabKey);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const tabsProps = {
        itemData: businessProcessData,
        setActiveTabKey,
        handleTabChange,
        refreshTab,
        setrefreshTab,
        // toggleTabDisabled,
        // tabDisabled,
        id: (id?.toLowerCase() === "add") ? null : id,
        moduleName: moduleName,
    };
    const tabsProps2 = {
        itemData: businessProcessData,
        setActiveTabKey,
        handleTabChange,
        refreshTab,
        setrefreshTab,
        // toggleTabDisabled,
        // tabDisabled,
        id: (id?.toLowerCase() === "add") ? null : id,
        moduleName: "ApqcsProcess",
    };

    const BusinessProcessTabs = [
        {
            label: `Information`,
            key: 'tab_information',
            children: <TabBPBasicInformation {...tabsProps} setBusinessProcessData={setBusinessProcessData} defaultFormData={{ sourceid: 0, levelid: location?.state?.newLevelId, parentid: location?.state?.ParentID }} locationData={location?.state} />,
        }
        ,
        {
            label: `Relations`,
            key: 'tab_connections',
            disabled: portfolioId ? false : true,
            children: <TabConnections {...tabsProps} />,
        }
        ,
        {
            label: `External Links`,
            key: 'tab_links',
            disabled: portfolioId ? false : true,
            children: <TabLinks {...tabsProps2} />,
        }
        ,
        {
            label: `Diagram`,
            key: 'tab_diagram',
            disabled: portfolioId ? false : true,
            children: <TabDiagrams {...tabsProps2} titleName={businessProcessData?.DisplayName} />,
        }
        ,
        {
            label: `Comments`,
            key: 'tab_comments',
            disabled: portfolioId ? false : true,
            children: <TabComments {...tabsProps} />,
        },
        {
            label: `Change log/ Alerts`,
            key: 'tab_changelogAlert',
            disabled: portfolioId ? false : true,
            children: <TabChangeLogAlerts {...tabsProps} />,
        }
    ];

    return (
        <div className=' mb-5 text-left'>
            <div className='d-flex justify-content-between align-items-center'>

                <PageTitleHeading text={
                    ((() => {
                        if (businessProcessData?.displayname) {
                            return <span>APQC Process: {businessProcessData?.levelid} {businessProcessData?.displayname}</span>;
                        }
                        return <span>Add New APQC Process</span>;
                    })())
                }
                />
                {scenarioId && (<div style={{ background: "#ffaa02", paddingTop: 1, paddingLeft: 5, paddingRight: 5, margin: 3, borderRadius: 5, height: 25 }}>
                    {scenarioName ? "CURRENT WORKSPACE: " + scenarioName : ""}
                </div>)}

                <div className='my-3'>
                    <GoBack className={`ml-2 pull-right`} path={location?.state?.from} state={location?.state} back />
                </div>
            </div>
            <Tabs
                type="card"
                className="business-capability-entry"
                activeKey={activeTabKey}
                onChange={handleTabChange}
                items={BusinessProcessTabs}
            />
        </div>
    );
}

export default ApqcProcessesEntry;