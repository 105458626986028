/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import { PageTitleHeading } from '../../../utils/Common';
import Learnig from './../../../assets/learning.jpeg';
import GlobalSearchServices from '../../../services/services/GlobalSearchServices';
import { settings } from '../../../utils/settingCommon';
import Delayed from '../../../utils/Delayed';

const HomeShortVideoGuide = () => {
    const [shortVideo, setShortVideo] = useState("");

    useEffect(()=>{
        getShortVideoUpload();
    },[])

    const getShortVideoUpload = async () => {
        
        try {
            let response = await GlobalSearchServices?.getSettings(settings?.home_page || "");
            if(response?.data?.linkUrl){
                setShortVideo(response?.data?.linkUrl);
            }
        } catch (error) {
            console.error("error:",error)
        } 
    }

    return (
        <React.Fragment>
            <PageTitleHeading text={"Short Video Guide"} className={`mt-2 mb-0`}/>
            {
                (()=>{
                    if(shortVideo){
                        return <React.Fragment>
                            <div className="embed-responsive embed-responsive-16by9">
                                <video width="320" height="240" controls>
                                    <source src={shortVideo} type="video/mp4" className="embed-responsive-item mt-0 pt-0"/>
                                    <source src={shortVideo} type="video/ogg" className="embed-responsive-item mt-0 pt-0"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </React.Fragment>
                    }else{
                        return <React.Fragment>
                            <Delayed waitBeforeShow={1000}>
                                <img src={Learnig} alt='Learning'/>
                            </Delayed>
                        </React.Fragment>
                    }
                })()
            }
        </React.Fragment>
    )
}

export default HomeShortVideoGuide
