import React, { useEffect, useState } from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import TextWithTooltip from '../../../utils/TextWithTooltip';
import { Link } from 'react-router-dom';
import { Flex } from 'antd';

const RecentlyViewed = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        getRecentViewed_Portfolio();
    },[]);
 
    const getRecentViewed_Portfolio=()=>{ 
        let recentPortfolio = localStorage.getItem("recent_viewed_portfolio"); 
        let recentPortfolio_json= JSON.parse(recentPortfolio)?.reverse().slice(0, 6);

        setList(recentPortfolio_json);  
    }
    
    return (
        <React.Fragment>
            <PageTitleHeading text={"Recently Viewed"} />
            <table className='table table-sm w-100 fs-smaller'>
                <thead class="table-active">
                    <tr>
                        <th>
                            <Flex gap={4} justify='space-between'>
                                <div> Name </div>
                                <div>Portfolio</div>
                            </Flex>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {   
                        list?.map((item) => {
                            return <React.Fragment key={Math?.random()}>
                                <tr>
                                    <td>
                                        <Flex gap={4} justify='space-between'>
                                            <div>
                                                <a href={item.URL}><PageTitleHeading text={<TextWithTooltip text={item?.ModelName} characterLimit={15}/>} size={5} className={`my-0`} tooltip={true}/></a>
                                            </div>
                                            <div>
                                                {/* <Link to={item.URL} style={{background : item?.color}} className='px-2 py-1 cu-border-rounded text-nowrap'>{item?.Module}</Link> */}
                                                <div style={{background : item?.color}} className='px-2 py-1 cu-border-rounded text-nowrap'>{item?.Module}</div>
                                            </div>
                                        </Flex>
                                    </td>
                                </tr>
                            </React.Fragment>
                        })
                    }
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default RecentlyViewed
