import './Home.scss';
import { Layout, message } from 'antd';
import React, { useEffect, useState } from 'react';
import HomeNotifications from './home-v2/HomeNotifications';
import HomeCharts from './home-v2/HomeCharts';
import RecentlyViewed from './home-v2/RecentlyViewed';
import HomeQuickLink from './HomeQuickLink';
import HomeShortVideoGuide from './home-v2/HomeShortVideoGuide';
import HomeQuickStart from './HomeQuickStart';
import HomePageServices from '../../services/services/HomePageServices';
import { useNotificationProvider } from '../../Context/NotificationProvider';

const HomeV2 = ({ ...props }) => {

    const [notificationData, setNotificationData] = useState([]);
    const { state: notificatioDataProvider, dispatch: dispatchNotificatioData } = useNotificationProvider();

    const getNotificationData = async () => {
        //asda
        const email = localStorage.getItem('email')
        if (email) {
            const data = {
                "userid": email
            }
            const notificationResult = await HomePageServices.getNotificationData(data);
            if (notificationResult.status === 200) {
                setNotificationData(notificationResult?.data)
                await dispatchNotificatioData({ type: "SET_DATA", payload: notificationResult?.data });
            } 
            else {
                message.error(notificationResult.message);
            }
        }
    }


    useEffect(() => {
        getNotificationData();
    }, []);

    return (
        <React.Fragment>
            <Layout className='home-v2'>
                <Layout.Content>
                    <div className='mr-2'>
                        <HomeNotifications notificationData={notificationData} getNotificationData={getNotificationData} />
                        <HomeCharts />
                    </div>
                </Layout.Content>
                <Layout.Sider theme='light' className='border-left px-4' style={{ minHeight: window?.innerHeight - 50 }} width={300}>
                    <RecentlyViewed />
                    <HomeQuickLink />
                    <HomeShortVideoGuide />
                    <HomeQuickStart />
                </Layout.Sider>
            </Layout>
        </React.Fragment>
    )
}

HomeV2.defaultProps = {

}

export default HomeV2
