import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import ScenarioList from "./ScenarioList";
import { SelectButtonCompareScenarioV1 } from "./scenarioCommon";
import { useNavigate } from "react-router";
import { checkRole } from "../../../helper/useUserData";

const CompareScenarioForm = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedScenario, setSelectedScenario] = useState([]);
  const [showScenarioListModal, setShowScenarioListModal] = useState(false);
  const [filteredScenario, setFilteredScenario] = useState([]);
  // form.setFieldValue('scenario_2', 2);

  const selectScenario = (id) => {
    setSelectedScenario([...selectedScenario, id]);
    form.setFieldValue("compareIds", [...selectedScenario, id]);
  };

  const removeSelected = (id) => {
    setSelectedScenario(selectedScenario.filter((f) => f !== id));
    form.setFieldValue(
      "compareIds",
      selectedScenario.filter((f) => f !== id)
    );
  };

  const onFinish = (values) => { 
    
    navigate("/compare-scenario-v2/compare",{state:{compare:values}})
  };
  useEffect(() => {
    setFilteredScenario(props?.list);
  }, [props?.list]);

  return (
    <div className="p-lg-5">
      <Form
        name="wrap"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelAlign="left"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
      >
        {/* <Form.Item
          label="Pick Base Scenario"
          name="scenarioId"
          rules={[{ required: true }]}
        >
          <Select
            options={(props?.list && props?.list?.length > 0) ? props?.list?.map((item) => {
              return {
                label: item?.name,
                value: item?.id,
              };
            }):[]}
            onChange={async (value) => {
              // Perform any custom action when scenarioId changes
              setFilteredScenario(
                props?.list?.filter((item) => item?.id !== value)
              );
              await removeSelected(value);

              // You can update state or perform any other action here
            }}
            notFoundContent="No Data Found"
          />
        </Form.Item> */}

        <Form.Item
          label="Choose scenario to comparison"
          name="compareIds"
          rules={[{ required: true }]}
        >
          <Input readOnly hidden />
          <div className="p-2 border rounded text-center">
            {selectedScenario?.map((id) => {
              let scen = filteredScenario?.find((f) => f.id === id);
              return (
                <div
                  className="w-100 card card-body mb-1 py-1"
                  key={Math.random()}
                >
                  <div className="d-flex justify-content-between ">
                    <div className="h5 mb-0">{scen?.name}</div>
                    <div>
                      <DeleteOutlined
                        style={{ color: "#2a609d" }}
                        className="cursor-pointer"
                        onClick={() => {
                          removeSelected(id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <Button
              type="default"
              htmlType="button"
              icon={<PlusOutlined />}
              shape="circle"
              onClick={() => setShowScenarioListModal(true)}
            />
          </div>
        </Form.Item>
        {checkRole('add') && <Button type="primary" htmlType="submit" className="px-5">
          Next
        </Button>}
      </Form>
      <Modal
        open={showScenarioListModal}
        footer={false}
        // className="cu-modal-p-0"
        width={(window?.innerWidth * 75) / 100}
        onCancel={() => setShowScenarioListModal(false)}
      >
        <ScenarioList
          {...props}
          list={filteredScenario}
          title={`Scenario List`}
          headerSearch={true}
          filter={true}
          Buttons={SelectButtonCompareScenarioV1}
          selectedScenario={selectedScenario}
          selectScenario={selectScenario}
        />
      </Modal>
    </div>
  );
};

CompareScenarioForm.propTypes = {};

export default CompareScenarioForm;
